.pub__header {
  font-weight: bold;
  margin-top: 5%;
  /* opacity: 0.5; */
}

.pub__title {
  font-style: italic;
  color: var(--primary-text);
  text-decoration: none;
}

.pub__authors {
  font-weight: bold;
}

.pub__year {
  font-style: oblique;
  margin-top: 3%;
}

.pub__conference {
  opacity: 0.5;
}

.pub__end {
  margin-bottom: 150px !important;
}

.MuiGrid-root {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.MuiGrid-root::-webkit-scrollbar {
  display: none;
}

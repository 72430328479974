#stars {
  width: 1px;
  height: 1px;
  background: transparent;
  box-shadow: 1434px 1212px #fff, 623px 1608px #fff, 824px 888px #fff,
    198px 459px #fff, 749px 1596px #fff, 600px 701px #fff, 905px 1655px #fff,
    1482px 1961px #fff, 1998px 1479px #fff, 1116px 691px #fff,
    1260px 1864px #fff, 441px 359px #fff, 1593px 1743px #fff, 1885px 1906px #fff,
    899px 1734px #fff, 1439px 258px #fff, 1074px 1092px #fff, 658px 1606px #fff,
    591px 1183px #fff, 1426px 1670px #fff, 1301px 482px #fff, 1990px 241px #fff,
    974px 1474px #fff, 30px 511px #fff, 1016px 1216px #fff, 1705px 321px #fff,
    349px 1745px #fff, 1386px 1301px #fff, 1314px 294px #fff, 1087px 1988px #fff,
    587px 1528px #fff, 1032px 1831px #fff, 871px 1787px #fff, 1207px 250px #fff,
    1856px 1097px #fff, 1875px 276px #fff, 1617px 1523px #fff, 1864px 551px #fff,
    66px 990px #fff, 1701px 990px #fff, 1177px 1208px #fff, 823px 492px #fff,
    79px 452px #fff, 1061px 110px #fff, 731px 681px #fff, 247px 872px #fff,
    1340px 1854px #fff, 789px 1565px #fff, 1889px 144px #fff, 715px 1770px #fff,
    529px 1774px #fff, 729px 1587px #fff, 327px 387px #fff, 1081px 977px #fff,
    542px 539px #fff, 1528px 346px #fff, 618px 1372px #fff, 654px 252px #fff,
    1616px 1426px #fff, 1573px 888px #fff, 511px 807px #fff, 1316px 606px #fff,
    729px 953px #fff, 1612px 747px #fff, 498px 1566px #fff, 1919px 1707px #fff,
    167px 535px #fff, 926px 1112px #fff, 1360px 1159px #fff, 1249px 1089px #fff,
    1658px 196px #fff, 1813px 1652px #fff, 748px 1015px #fff, 116px 598px #fff,
    508px 896px #fff, 1959px 1235px #fff, 785px 1060px #fff, 1614px 1645px #fff,
    245px 875px #fff, 1161px 923px #fff, 126px 1982px #fff, 254px 1834px #fff,
    1887px 51px #fff, 915px 1880px #fff, 1850px 15px #fff, 1352px 499px #fff,
    354px 1284px #fff, 1385px 1558px #fff, 1482px 1219px #fff,
    1130px 1194px #fff, 1823px 1149px #fff, 710px 325px #fff, 389px 990px #fff,
    929px 622px #fff, 1729px 288px #fff, 669px 1784px #fff, 1385px 202px #fff,
    1262px 1629px #fff, 1364px 1705px #fff, 898px 141px #fff, 758px 1736px #fff,
    734px 948px #fff, 1700px 330px #fff, 802px 1286px #fff, 831px 1100px #fff,
    1719px 1036px #fff, 1922px 595px #fff, 803px 1011px #fff, 1118px 48px #fff,
    1166px 1071px #fff, 1387px 947px #fff, 487px 700px #fff, 1699px 1306px #fff,
    1785px 1808px #fff, 917px 1153px #fff, 483px 1600px #fff, 1934px 965px #fff,
    975px 75px #fff, 242px 307px #fff, 13px 468px #fff, 296px 539px #fff,
    874px 1866px #fff, 722px 1143px #fff, 207px 1569px #fff, 58px 852px #fff,
    1613px 864px #fff, 1808px 934px #fff, 905px 1339px #fff, 608px 1208px #fff,
    261px 1638px #fff, 290px 1514px #fff, 344px 1412px #fff, 1970px 1173px #fff,
    982px 360px #fff, 722px 1402px #fff, 551px 1014px #fff, 305px 1166px #fff,
    1314px 1244px #fff, 342px 51px #fff, 1708px 1828px #fff, 1845px 1619px #fff,
    1641px 952px #fff, 1930px 943px #fff, 1365px 365px #fff, 1598px 1219px #fff,
    1662px 1741px #fff, 1539px 1252px #fff, 850px 157px #fff, 1924px 1244px #fff,
    1391px 1830px #fff, 575px 700px #fff, 1685px 1218px #fff, 1077px 394px #fff,
    1514px 1929px #fff, 870px 949px #fff, 82px 1590px #fff, 1643px 867px #fff,
    951px 1459px #fff, 1567px 1468px #fff, 185px 1461px #fff, 648px 200px #fff,
    1927px 1245px #fff, 1719px 999px #fff, 1451px 163px #fff, 1395px 1363px #fff,
    1727px 420px #fff, 259px 1522px #fff, 925px 1155px #fff, 419px 428px #fff,
    621px 1238px #fff, 1860px 96px #fff, 1507px 262px #fff, 1137px 543px #fff,
    1683px 1713px #fff, 53px 281px #fff, 1654px 45px #fff, 1440px 787px #fff,
    63px 875px #fff, 1039px 1490px #fff, 758px 45px #fff, 1013px 1259px #fff,
    155px 1808px #fff, 611px 1042px #fff, 1011px 1705px #fff, 318px 1924px #fff,
    1181px 484px #fff, 722px 1036px #fff, 962px 149px #fff, 863px 1767px #fff,
    1799px 994px #fff, 832px 1109px #fff, 741px 177px #fff, 654px 574px #fff,
    74px 212px #fff, 946px 1523px #fff, 1669px 405px #fff, 1656px 1270px #fff,
    982px 780px #fff, 822px 756px #fff, 148px 1138px #fff, 1959px 1154px #fff,
    223px 415px #fff, 1843px 1824px #fff, 766px 1990px #fff, 577px 1817px #fff,
    911px 1826px #fff, 1759px 1845px #fff, 19px 1514px #fff, 100px 433px #fff,
    391px 328px #fff, 1160px 1708px #fff, 751px 1630px #fff, 1906px 568px #fff,
    687px 877px #fff, 1961px 163px #fff, 745px 1031px #fff, 166px 1621px #fff,
    1128px 1343px #fff, 260px 357px #fff, 1111px 1041px #fff, 146px 13px #fff,
    856px 480px #fff, 1476px 1541px #fff, 937px 448px #fff, 851px 1634px #fff,
    1566px 1392px #fff, 558px 1020px #fff, 1844px 396px #fff, 623px 314px #fff,
    892px 1509px #fff, 1802px 584px #fff, 1484px 1927px #fff, 441px 1024px #fff,
    202px 54px #fff, 1599px 490px #fff, 1208px 520px #fff, 1185px 217px #fff,
    1600px 566px #fff, 269px 1031px #fff, 1203px 1794px #fff, 1408px 407px #fff,
    1954px 786px #fff, 270px 30px #fff, 464px 1736px #fff, 1041px 1110px #fff,
    1317px 1162px #fff, 607px 1624px #fff, 1519px 1172px #fff, 743px 1920px #fff,
    105px 1960px #fff, 1125px 1437px #fff, 1630px 713px #fff, 1521px 1876px #fff,
    297px 709px #fff, 449px 198px #fff, 643px 1219px #fff, 176px 85px #fff,
    1248px 1992px #fff, 353px 839px #fff, 206px 1823px #fff, 1299px 22px #fff,
    1831px 1384px #fff, 1155px 1815px #fff, 1784px 1297px #fff,
    573px 1701px #fff, 108px 1796px #fff, 358px 1766px #fff, 1038px 807px #fff,
    771px 338px #fff, 1525px 1719px #fff, 515px 1474px #fff, 1502px 1843px #fff,
    178px 1326px #fff, 1566px 184px #fff, 1361px 1826px #fff, 708px 233px #fff,
    169px 760px #fff, 809px 452px #fff, 1404px 750px #fff, 1843px 922px #fff,
    1052px 1731px #fff, 827px 598px #fff, 1142px 805px #fff, 1265px 37px #fff,
    16px 1796px #fff, 1355px 1727px #fff, 1073px 1372px #fff, 549px 641px #fff,
    570px 615px #fff, 138px 850px #fff, 1658px 1927px #fff, 452px 1233px #fff,
    434px 1151px #fff, 1669px 1976px #fff, 1788px 1418px #fff, 409px 1024px #fff,
    1984px 930px #fff, 509px 1886px #fff, 101px 1463px #fff, 347px 1316px #fff,
    474px 932px #fff, 1085px 1024px #fff, 340px 628px #fff, 149px 1849px #fff,
    1823px 1130px #fff, 1991px 36px #fff, 1369px 1826px #fff, 361px 1370px #fff,
    1743px 1019px #fff, 162px 362px #fff, 752px 38px #fff, 1836px 816px #fff,
    1539px 996px #fff, 3px 1105px #fff, 897px 510px #fff, 594px 1782px #fff,
    1791px 1941px #fff, 1815px 1025px #fff, 547px 734px #fff, 297px 162px #fff,
    815px 1470px #fff, 1316px 625px #fff, 745px 1979px #fff, 1801px 1206px #fff,
    941px 1645px #fff, 669px 263px #fff, 143px 167px #fff, 1165px 73px #fff,
    1024px 1598px #fff, 1111px 57px #fff, 1311px 330px #fff, 1907px 1313px #fff,
    1711px 1316px #fff, 720px 229px #fff, 1542px 852px #fff, 1268px 1807px #fff,
    177px 1467px #fff, 58px 879px #fff, 260px 86px #fff, 978px 771px #fff,
    1419px 987px #fff, 1243px 1540px #fff, 558px 898px #fff, 1302px 1807px #fff,
    1062px 1724px #fff, 1171px 126px #fff, 1102px 568px #fff, 1167px 539px #fff,
    1992px 1937px #fff, 244px 1720px #fff, 509px 1365px #fff, 635px 9px #fff,
    121px 677px #fff, 226px 1249px #fff, 609px 1374px #fff, 116px 385px #fff,
    1243px 1044px #fff, 1553px 601px #fff, 1385px 1303px #fff, 1668px 600px #fff,
    544px 746px #fff, 1597px 811px #fff, 1115px 617px #fff, 48px 535px #fff,
    56px 1438px #fff, 337px 792px #fff, 851px 296px #fff, 1775px 400px #fff,
    75px 1698px #fff, 1232px 132px #fff, 1155px 140px #fff, 505px 1648px #fff,
    1531px 669px #fff, 919px 725px #fff, 1940px 579px #fff, 171px 1084px #fff,
    1483px 586px #fff, 1717px 880px #fff, 1094px 89px #fff, 115px 182px #fff,
    1181px 1053px #fff, 1907px 1568px #fff, 98px 844px #fff, 504px 119px #fff,
    1308px 1877px #fff, 1391px 1488px #fff, 1414px 1638px #fff,
    285px 1717px #fff, 371px 1556px #fff, 1350px 1890px #fff, 1242px 1782px #fff,
    1064px 1213px #fff, 788px 946px #fff, 1311px 1446px #fff, 971px 1788px #fff,
    387px 1538px #fff, 945px 956px #fff, 1555px 15px #fff, 670px 1771px #fff,
    453px 1498px #fff, 1826px 933px #fff, 302px 1774px #fff, 205px 674px #fff,
    649px 1694px #fff, 483px 568px #fff, 1050px 1730px #fff, 1260px 1255px #fff,
    1260px 1718px #fff, 1152px 1690px #fff, 1388px 1080px #fff, 380px 117px #fff,
    942px 1901px #fff, 1291px 941px #fff, 1897px 749px #fff, 1743px 1968px #fff,
    1483px 454px #fff, 1667px 869px #fff, 713px 1261px #fff, 113px 479px #fff,
    1817px 1156px #fff, 496px 1155px #fff, 1140px 1370px #fff, 1874px 930px #fff,
    132px 835px #fff, 1984px 304px #fff, 1189px 420px #fff, 1811px 779px #fff,
    1852px 88px #fff, 1816px 1884px #fff, 1610px 1556px #fff, 1660px 1614px #fff,
    656px 767px #fff, 836px 536px #fff, 1565px 1775px #fff, 1119px 1121px #fff,
    543px 1886px #fff, 807px 112px #fff, 406px 104px #fff, 12px 927px #fff,
    57px 1429px #fff, 970px 346px #fff, 1632px 1578px #fff, 204px 178px #fff,
    1814px 866px #fff, 1212px 654px #fff, 982px 968px #fff, 1322px 1982px #fff,
    306px 93px #fff, 742px 1930px #fff, 1519px 664px #fff, 1055px 429px #fff,
    1301px 1401px #fff, 393px 1858px #fff, 1184px 321px #fff, 1399px 273px #fff,
    1101px 615px #fff, 1424px 804px #fff, 1048px 600px #fff, 1999px 9px #fff,
    658px 462px #fff, 1578px 1368px #fff, 1004px 1741px #fff, 719px 1763px #fff,
    1348px 1661px #fff, 166px 1893px #fff, 1103px 1450px #fff, 485px 111px #fff,
    1942px 98px #fff, 1618px 1416px #fff, 220px 1985px #fff, 306px 240px #fff,
    778px 1843px #fff, 1430px 1291px #fff, 1824px 1941px #fff, 165px 1475px #fff,
    1024px 1848px #fff, 1892px 1557px #fff, 703px 767px #fff, 434px 1594px #fff,
    1812px 967px #fff, 956px 717px #fff, 1168px 400px #fff, 1963px 949px #fff,
    1623px 491px #fff, 920px 1674px #fff, 896px 1724px #fff, 1437px 1929px #fff,
    881px 1866px #fff, 1008px 84px #fff, 1266px 1170px #fff, 877px 932px #fff,
    387px 914px #fff, 95px 552px #fff, 104px 1117px #fff, 1837px 507px #fff,
    1135px 1169px #fff, 1988px 876px #fff, 1238px 699px #fff, 593px 73px #fff,
    1665px 741px #fff, 892px 119px #fff, 1693px 1756px #fff, 1640px 1437px #fff,
    392px 169px #fff, 1766px 1574px #fff, 1688px 1530px #fff, 1750px 1791px #fff,
    579px 553px #fff, 1289px 1691px #fff, 533px 636px #fff, 1909px 1486px #fff,
    762px 476px #fff, 349px 1115px #fff, 1984px 519px #fff, 1661px 1005px #fff,
    1213px 862px #fff, 1274px 497px #fff, 1618px 1610px #fff, 1944px 923px #fff,
    943px 1805px #fff, 225px 1408px #fff, 907px 432px #fff, 249px 640px #fff,
    105px 487px #fff, 996px 1109px #fff, 1207px 1731px #fff, 278px 296px #fff,
    27px 281px #fff, 1724px 1523px #fff, 857px 98px #fff, 947px 1637px #fff,
    733px 1848px #fff, 1810px 33px #fff, 1940px 1529px #fff, 1217px 1696px #fff,
    25px 1452px #fff, 1134px 1957px #fff, 282px 1682px #fff, 680px 1406px #fff,
    38px 817px #fff, 1562px 555px #fff, 269px 1046px #fff, 1360px 1205px #fff,
    1330px 1644px #fff, 1672px 1779px #fff, 148px 1762px #fff,
    1824px 1029px #fff, 59px 1800px #fff, 695px 357px #fff, 1923px 1148px #fff,
    1130px 1799px #fff, 1589px 1730px #fff, 931px 1110px #fff, 444px 1293px #fff,
    939px 634px #fff, 242px 71px #fff, 974px 1675px #fff, 1233px 1841px #fff,
    1213px 1489px #fff, 334px 1139px #fff, 1634px 517px #fff, 623px 1134px #fff,
    458px 1253px #fff, 529px 1499px #fff, 775px 267px #fff, 113px 494px #fff,
    1347px 1317px #fff, 723px 902px #fff, 1218px 1828px #fff, 234px 1295px #fff,
    56px 123px #fff, 623px 777px #fff, 316px 928px #fff, 1523px 31px #fff,
    1047px 1091px #fff, 1208px 1474px #fff, 368px 1385px #fff, 439px 1540px #fff,
    818px 1730px #fff, 182px 1834px #fff, 1454px 1965px #fff, 672px 466px #fff,
    214px 1631px #fff, 739px 8px #fff, 1417px 1578px #fff, 671px 1px #fff,
    937px 698px #fff, 255px 1674px #fff, 1055px 751px #fff, 53px 150px #fff,
    710px 775px #fff, 1940px 1250px #fff, 757px 1497px #fff, 711px 1811px #fff,
    692px 859px #fff, 1085px 1326px #fff, 242px 411px #fff, 252px 459px #fff,
    977px 238px #fff, 1237px 431px #fff, 603px 1940px #fff, 410px 1558px #fff,
    117px 1975px #fff, 156px 344px #fff, 506px 209px #fff, 1693px 1129px #fff,
    1553px 1311px #fff, 964px 1102px #fff, 359px 1417px #fff, 1738px 770px #fff,
    1983px 1134px #fff, 707px 902px #fff, 774px 144px #fff, 614px 308px #fff,
    1586px 563px #fff, 442px 1426px #fff, 446px 1009px #fff, 647px 1797px #fff,
    633px 852px #fff, 140px 1199px #fff, 1522px 1301px #fff, 1687px 1157px #fff,
    307px 650px #fff, 655px 889px #fff, 67px 1386px #fff, 1009px 1083px #fff,
    224px 1030px #fff, 628px 301px #fff, 1920px 1474px #fff, 1227px 619px #fff,
    1634px 493px #fff, 366px 1707px #fff, 1170px 905px #fff, 1599px 1804px #fff,
    921px 398px #fff, 1888px 921px #fff, 1178px 648px #fff, 1854px 1164px #fff,
    22px 927px #fff, 388px 1884px #fff, 690px 705px #fff, 1712px 1296px #fff,
    248px 849px #fff, 660px 958px #fff, 536px 1440px #fff, 1245px 1535px #fff,
    755px 783px #fff, 729px 326px #fff, 1283px 741px #fff, 1042px 493px #fff,
    204px 1170px #fff, 1871px 1869px #fff, 1738px 202px #fff, 236px 25px #fff,
    1319px 1675px #fff, 81px 816px #fff, 112px 193px #fff, 1019px 1140px #fff,
    1970px 347px #fff, 284px 1641px #fff, 1631px 429px #fff, 446px 8px #fff,
    978px 966px #fff, 1733px 372px #fff, 1647px 1919px #fff, 1419px 1379px #fff,
    463px 197px #fff, 267px 635px #fff, 580px 1660px #fff, 1406px 978px #fff,
    409px 1663px #fff, 37px 394px #fff, 966px 589px #fff, 1330px 1963px #fff,
    701px 600px #fff, 1273px 1379px #fff, 453px 563px #fff, 140px 1847px #fff,
    458px 971px #fff, 633px 1656px #fff, 162px 1772px #fff, 1929px 1889px #fff,
    433px 1067px #fff, 162px 351px #fff, 1049px 1800px #fff, 1577px 1610px #fff,
    697px 390px #fff, 1455px 1404px #fff, 740px 220px #fff, 1887px 1170px #fff,
    1175px 332px #fff, 1050px 728px #fff, 1127px 677px #fff, 1560px 420px #fff,
    716px 87px #fff, 1253px 1193px #fff, 413px 1682px #fff, 1919px 1539px #fff,
    848px 1424px #fff, 1510px 143px #fff;
  animation: animStar 50s linear infinite;
}
#stars:after {
  content: " ";
  position: absolute;
  top: 2000px;
  width: 1px;
  height: 1px;
  background: transparent;
  box-shadow: 1434px 1212px #fff, 623px 1608px #fff, 824px 888px #fff,
    198px 459px #fff, 749px 1596px #fff, 600px 701px #fff, 905px 1655px #fff,
    1482px 1961px #fff, 1998px 1479px #fff, 1116px 691px #fff,
    1260px 1864px #fff, 441px 359px #fff, 1593px 1743px #fff, 1885px 1906px #fff,
    899px 1734px #fff, 1439px 258px #fff, 1074px 1092px #fff, 658px 1606px #fff,
    591px 1183px #fff, 1426px 1670px #fff, 1301px 482px #fff, 1990px 241px #fff,
    974px 1474px #fff, 30px 511px #fff, 1016px 1216px #fff, 1705px 321px #fff,
    349px 1745px #fff, 1386px 1301px #fff, 1314px 294px #fff, 1087px 1988px #fff,
    587px 1528px #fff, 1032px 1831px #fff, 871px 1787px #fff, 1207px 250px #fff,
    1856px 1097px #fff, 1875px 276px #fff, 1617px 1523px #fff, 1864px 551px #fff,
    66px 990px #fff, 1701px 990px #fff, 1177px 1208px #fff, 823px 492px #fff,
    79px 452px #fff, 1061px 110px #fff, 731px 681px #fff, 247px 872px #fff,
    1340px 1854px #fff, 789px 1565px #fff, 1889px 144px #fff, 715px 1770px #fff,
    529px 1774px #fff, 729px 1587px #fff, 327px 387px #fff, 1081px 977px #fff,
    542px 539px #fff, 1528px 346px #fff, 618px 1372px #fff, 654px 252px #fff,
    1616px 1426px #fff, 1573px 888px #fff, 511px 807px #fff, 1316px 606px #fff,
    729px 953px #fff, 1612px 747px #fff, 498px 1566px #fff, 1919px 1707px #fff,
    167px 535px #fff, 926px 1112px #fff, 1360px 1159px #fff, 1249px 1089px #fff,
    1658px 196px #fff, 1813px 1652px #fff, 748px 1015px #fff, 116px 598px #fff,
    508px 896px #fff, 1959px 1235px #fff, 785px 1060px #fff, 1614px 1645px #fff,
    245px 875px #fff, 1161px 923px #fff, 126px 1982px #fff, 254px 1834px #fff,
    1887px 51px #fff, 915px 1880px #fff, 1850px 15px #fff, 1352px 499px #fff,
    354px 1284px #fff, 1385px 1558px #fff, 1482px 1219px #fff,
    1130px 1194px #fff, 1823px 1149px #fff, 710px 325px #fff, 389px 990px #fff,
    929px 622px #fff, 1729px 288px #fff, 669px 1784px #fff, 1385px 202px #fff,
    1262px 1629px #fff, 1364px 1705px #fff, 898px 141px #fff, 758px 1736px #fff,
    734px 948px #fff, 1700px 330px #fff, 802px 1286px #fff, 831px 1100px #fff,
    1719px 1036px #fff, 1922px 595px #fff, 803px 1011px #fff, 1118px 48px #fff,
    1166px 1071px #fff, 1387px 947px #fff, 487px 700px #fff, 1699px 1306px #fff,
    1785px 1808px #fff, 917px 1153px #fff, 483px 1600px #fff, 1934px 965px #fff,
    975px 75px #fff, 242px 307px #fff, 13px 468px #fff, 296px 539px #fff,
    874px 1866px #fff, 722px 1143px #fff, 207px 1569px #fff, 58px 852px #fff,
    1613px 864px #fff, 1808px 934px #fff, 905px 1339px #fff, 608px 1208px #fff,
    261px 1638px #fff, 290px 1514px #fff, 344px 1412px #fff, 1970px 1173px #fff,
    982px 360px #fff, 722px 1402px #fff, 551px 1014px #fff, 305px 1166px #fff,
    1314px 1244px #fff, 342px 51px #fff, 1708px 1828px #fff, 1845px 1619px #fff,
    1641px 952px #fff, 1930px 943px #fff, 1365px 365px #fff, 1598px 1219px #fff,
    1662px 1741px #fff, 1539px 1252px #fff, 850px 157px #fff, 1924px 1244px #fff,
    1391px 1830px #fff, 575px 700px #fff, 1685px 1218px #fff, 1077px 394px #fff,
    1514px 1929px #fff, 870px 949px #fff, 82px 1590px #fff, 1643px 867px #fff,
    951px 1459px #fff, 1567px 1468px #fff, 185px 1461px #fff, 648px 200px #fff,
    1927px 1245px #fff, 1719px 999px #fff, 1451px 163px #fff, 1395px 1363px #fff,
    1727px 420px #fff, 259px 1522px #fff, 925px 1155px #fff, 419px 428px #fff,
    621px 1238px #fff, 1860px 96px #fff, 1507px 262px #fff, 1137px 543px #fff,
    1683px 1713px #fff, 53px 281px #fff, 1654px 45px #fff, 1440px 787px #fff,
    63px 875px #fff, 1039px 1490px #fff, 758px 45px #fff, 1013px 1259px #fff,
    155px 1808px #fff, 611px 1042px #fff, 1011px 1705px #fff, 318px 1924px #fff,
    1181px 484px #fff, 722px 1036px #fff, 962px 149px #fff, 863px 1767px #fff,
    1799px 994px #fff, 832px 1109px #fff, 741px 177px #fff, 654px 574px #fff,
    74px 212px #fff, 946px 1523px #fff, 1669px 405px #fff, 1656px 1270px #fff,
    982px 780px #fff, 822px 756px #fff, 148px 1138px #fff, 1959px 1154px #fff,
    223px 415px #fff, 1843px 1824px #fff, 766px 1990px #fff, 577px 1817px #fff,
    911px 1826px #fff, 1759px 1845px #fff, 19px 1514px #fff, 100px 433px #fff,
    391px 328px #fff, 1160px 1708px #fff, 751px 1630px #fff, 1906px 568px #fff,
    687px 877px #fff, 1961px 163px #fff, 745px 1031px #fff, 166px 1621px #fff,
    1128px 1343px #fff, 260px 357px #fff, 1111px 1041px #fff, 146px 13px #fff,
    856px 480px #fff, 1476px 1541px #fff, 937px 448px #fff, 851px 1634px #fff,
    1566px 1392px #fff, 558px 1020px #fff, 1844px 396px #fff, 623px 314px #fff,
    892px 1509px #fff, 1802px 584px #fff, 1484px 1927px #fff, 441px 1024px #fff,
    202px 54px #fff, 1599px 490px #fff, 1208px 520px #fff, 1185px 217px #fff,
    1600px 566px #fff, 269px 1031px #fff, 1203px 1794px #fff, 1408px 407px #fff,
    1954px 786px #fff, 270px 30px #fff, 464px 1736px #fff, 1041px 1110px #fff,
    1317px 1162px #fff, 607px 1624px #fff, 1519px 1172px #fff, 743px 1920px #fff,
    105px 1960px #fff, 1125px 1437px #fff, 1630px 713px #fff, 1521px 1876px #fff,
    297px 709px #fff, 449px 198px #fff, 643px 1219px #fff, 176px 85px #fff,
    1248px 1992px #fff, 353px 839px #fff, 206px 1823px #fff, 1299px 22px #fff,
    1831px 1384px #fff, 1155px 1815px #fff, 1784px 1297px #fff,
    573px 1701px #fff, 108px 1796px #fff, 358px 1766px #fff, 1038px 807px #fff,
    771px 338px #fff, 1525px 1719px #fff, 515px 1474px #fff, 1502px 1843px #fff,
    178px 1326px #fff, 1566px 184px #fff, 1361px 1826px #fff, 708px 233px #fff,
    169px 760px #fff, 809px 452px #fff, 1404px 750px #fff, 1843px 922px #fff,
    1052px 1731px #fff, 827px 598px #fff, 1142px 805px #fff, 1265px 37px #fff,
    16px 1796px #fff, 1355px 1727px #fff, 1073px 1372px #fff, 549px 641px #fff,
    570px 615px #fff, 138px 850px #fff, 1658px 1927px #fff, 452px 1233px #fff,
    434px 1151px #fff, 1669px 1976px #fff, 1788px 1418px #fff, 409px 1024px #fff,
    1984px 930px #fff, 509px 1886px #fff, 101px 1463px #fff, 347px 1316px #fff,
    474px 932px #fff, 1085px 1024px #fff, 340px 628px #fff, 149px 1849px #fff,
    1823px 1130px #fff, 1991px 36px #fff, 1369px 1826px #fff, 361px 1370px #fff,
    1743px 1019px #fff, 162px 362px #fff, 752px 38px #fff, 1836px 816px #fff,
    1539px 996px #fff, 3px 1105px #fff, 897px 510px #fff, 594px 1782px #fff,
    1791px 1941px #fff, 1815px 1025px #fff, 547px 734px #fff, 297px 162px #fff,
    815px 1470px #fff, 1316px 625px #fff, 745px 1979px #fff, 1801px 1206px #fff,
    941px 1645px #fff, 669px 263px #fff, 143px 167px #fff, 1165px 73px #fff,
    1024px 1598px #fff, 1111px 57px #fff, 1311px 330px #fff, 1907px 1313px #fff,
    1711px 1316px #fff, 720px 229px #fff, 1542px 852px #fff, 1268px 1807px #fff,
    177px 1467px #fff, 58px 879px #fff, 260px 86px #fff, 978px 771px #fff,
    1419px 987px #fff, 1243px 1540px #fff, 558px 898px #fff, 1302px 1807px #fff,
    1062px 1724px #fff, 1171px 126px #fff, 1102px 568px #fff, 1167px 539px #fff,
    1992px 1937px #fff, 244px 1720px #fff, 509px 1365px #fff, 635px 9px #fff,
    121px 677px #fff, 226px 1249px #fff, 609px 1374px #fff, 116px 385px #fff,
    1243px 1044px #fff, 1553px 601px #fff, 1385px 1303px #fff, 1668px 600px #fff,
    544px 746px #fff, 1597px 811px #fff, 1115px 617px #fff, 48px 535px #fff,
    56px 1438px #fff, 337px 792px #fff, 851px 296px #fff, 1775px 400px #fff,
    75px 1698px #fff, 1232px 132px #fff, 1155px 140px #fff, 505px 1648px #fff,
    1531px 669px #fff, 919px 725px #fff, 1940px 579px #fff, 171px 1084px #fff,
    1483px 586px #fff, 1717px 880px #fff, 1094px 89px #fff, 115px 182px #fff,
    1181px 1053px #fff, 1907px 1568px #fff, 98px 844px #fff, 504px 119px #fff,
    1308px 1877px #fff, 1391px 1488px #fff, 1414px 1638px #fff,
    285px 1717px #fff, 371px 1556px #fff, 1350px 1890px #fff, 1242px 1782px #fff,
    1064px 1213px #fff, 788px 946px #fff, 1311px 1446px #fff, 971px 1788px #fff,
    387px 1538px #fff, 945px 956px #fff, 1555px 15px #fff, 670px 1771px #fff,
    453px 1498px #fff, 1826px 933px #fff, 302px 1774px #fff, 205px 674px #fff,
    649px 1694px #fff, 483px 568px #fff, 1050px 1730px #fff, 1260px 1255px #fff,
    1260px 1718px #fff, 1152px 1690px #fff, 1388px 1080px #fff, 380px 117px #fff,
    942px 1901px #fff, 1291px 941px #fff, 1897px 749px #fff, 1743px 1968px #fff,
    1483px 454px #fff, 1667px 869px #fff, 713px 1261px #fff, 113px 479px #fff,
    1817px 1156px #fff, 496px 1155px #fff, 1140px 1370px #fff, 1874px 930px #fff,
    132px 835px #fff, 1984px 304px #fff, 1189px 420px #fff, 1811px 779px #fff,
    1852px 88px #fff, 1816px 1884px #fff, 1610px 1556px #fff, 1660px 1614px #fff,
    656px 767px #fff, 836px 536px #fff, 1565px 1775px #fff, 1119px 1121px #fff,
    543px 1886px #fff, 807px 112px #fff, 406px 104px #fff, 12px 927px #fff,
    57px 1429px #fff, 970px 346px #fff, 1632px 1578px #fff, 204px 178px #fff,
    1814px 866px #fff, 1212px 654px #fff, 982px 968px #fff, 1322px 1982px #fff,
    306px 93px #fff, 742px 1930px #fff, 1519px 664px #fff, 1055px 429px #fff,
    1301px 1401px #fff, 393px 1858px #fff, 1184px 321px #fff, 1399px 273px #fff,
    1101px 615px #fff, 1424px 804px #fff, 1048px 600px #fff, 1999px 9px #fff,
    658px 462px #fff, 1578px 1368px #fff, 1004px 1741px #fff, 719px 1763px #fff,
    1348px 1661px #fff, 166px 1893px #fff, 1103px 1450px #fff, 485px 111px #fff,
    1942px 98px #fff, 1618px 1416px #fff, 220px 1985px #fff, 306px 240px #fff,
    778px 1843px #fff, 1430px 1291px #fff, 1824px 1941px #fff, 165px 1475px #fff,
    1024px 1848px #fff, 1892px 1557px #fff, 703px 767px #fff, 434px 1594px #fff,
    1812px 967px #fff, 956px 717px #fff, 1168px 400px #fff, 1963px 949px #fff,
    1623px 491px #fff, 920px 1674px #fff, 896px 1724px #fff, 1437px 1929px #fff,
    881px 1866px #fff, 1008px 84px #fff, 1266px 1170px #fff, 877px 932px #fff,
    387px 914px #fff, 95px 552px #fff, 104px 1117px #fff, 1837px 507px #fff,
    1135px 1169px #fff, 1988px 876px #fff, 1238px 699px #fff, 593px 73px #fff,
    1665px 741px #fff, 892px 119px #fff, 1693px 1756px #fff, 1640px 1437px #fff,
    392px 169px #fff, 1766px 1574px #fff, 1688px 1530px #fff, 1750px 1791px #fff,
    579px 553px #fff, 1289px 1691px #fff, 533px 636px #fff, 1909px 1486px #fff,
    762px 476px #fff, 349px 1115px #fff, 1984px 519px #fff, 1661px 1005px #fff,
    1213px 862px #fff, 1274px 497px #fff, 1618px 1610px #fff, 1944px 923px #fff,
    943px 1805px #fff, 225px 1408px #fff, 907px 432px #fff, 249px 640px #fff,
    105px 487px #fff, 996px 1109px #fff, 1207px 1731px #fff, 278px 296px #fff,
    27px 281px #fff, 1724px 1523px #fff, 857px 98px #fff, 947px 1637px #fff,
    733px 1848px #fff, 1810px 33px #fff, 1940px 1529px #fff, 1217px 1696px #fff,
    25px 1452px #fff, 1134px 1957px #fff, 282px 1682px #fff, 680px 1406px #fff,
    38px 817px #fff, 1562px 555px #fff, 269px 1046px #fff, 1360px 1205px #fff,
    1330px 1644px #fff, 1672px 1779px #fff, 148px 1762px #fff,
    1824px 1029px #fff, 59px 1800px #fff, 695px 357px #fff, 1923px 1148px #fff,
    1130px 1799px #fff, 1589px 1730px #fff, 931px 1110px #fff, 444px 1293px #fff,
    939px 634px #fff, 242px 71px #fff, 974px 1675px #fff, 1233px 1841px #fff,
    1213px 1489px #fff, 334px 1139px #fff, 1634px 517px #fff, 623px 1134px #fff,
    458px 1253px #fff, 529px 1499px #fff, 775px 267px #fff, 113px 494px #fff,
    1347px 1317px #fff, 723px 902px #fff, 1218px 1828px #fff, 234px 1295px #fff,
    56px 123px #fff, 623px 777px #fff, 316px 928px #fff, 1523px 31px #fff,
    1047px 1091px #fff, 1208px 1474px #fff, 368px 1385px #fff, 439px 1540px #fff,
    818px 1730px #fff, 182px 1834px #fff, 1454px 1965px #fff, 672px 466px #fff,
    214px 1631px #fff, 739px 8px #fff, 1417px 1578px #fff, 671px 1px #fff,
    937px 698px #fff, 255px 1674px #fff, 1055px 751px #fff, 53px 150px #fff,
    710px 775px #fff, 1940px 1250px #fff, 757px 1497px #fff, 711px 1811px #fff,
    692px 859px #fff, 1085px 1326px #fff, 242px 411px #fff, 252px 459px #fff,
    977px 238px #fff, 1237px 431px #fff, 603px 1940px #fff, 410px 1558px #fff,
    117px 1975px #fff, 156px 344px #fff, 506px 209px #fff, 1693px 1129px #fff,
    1553px 1311px #fff, 964px 1102px #fff, 359px 1417px #fff, 1738px 770px #fff,
    1983px 1134px #fff, 707px 902px #fff, 774px 144px #fff, 614px 308px #fff,
    1586px 563px #fff, 442px 1426px #fff, 446px 1009px #fff, 647px 1797px #fff,
    633px 852px #fff, 140px 1199px #fff, 1522px 1301px #fff, 1687px 1157px #fff,
    307px 650px #fff, 655px 889px #fff, 67px 1386px #fff, 1009px 1083px #fff,
    224px 1030px #fff, 628px 301px #fff, 1920px 1474px #fff, 1227px 619px #fff,
    1634px 493px #fff, 366px 1707px #fff, 1170px 905px #fff, 1599px 1804px #fff,
    921px 398px #fff, 1888px 921px #fff, 1178px 648px #fff, 1854px 1164px #fff,
    22px 927px #fff, 388px 1884px #fff, 690px 705px #fff, 1712px 1296px #fff,
    248px 849px #fff, 660px 958px #fff, 536px 1440px #fff, 1245px 1535px #fff,
    755px 783px #fff, 729px 326px #fff, 1283px 741px #fff, 1042px 493px #fff,
    204px 1170px #fff, 1871px 1869px #fff, 1738px 202px #fff, 236px 25px #fff,
    1319px 1675px #fff, 81px 816px #fff, 112px 193px #fff, 1019px 1140px #fff,
    1970px 347px #fff, 284px 1641px #fff, 1631px 429px #fff, 446px 8px #fff,
    978px 966px #fff, 1733px 372px #fff, 1647px 1919px #fff, 1419px 1379px #fff,
    463px 197px #fff, 267px 635px #fff, 580px 1660px #fff, 1406px 978px #fff,
    409px 1663px #fff, 37px 394px #fff, 966px 589px #fff, 1330px 1963px #fff,
    701px 600px #fff, 1273px 1379px #fff, 453px 563px #fff, 140px 1847px #fff,
    458px 971px #fff, 633px 1656px #fff, 162px 1772px #fff, 1929px 1889px #fff,
    433px 1067px #fff, 162px 351px #fff, 1049px 1800px #fff, 1577px 1610px #fff,
    697px 390px #fff, 1455px 1404px #fff, 740px 220px #fff, 1887px 1170px #fff,
    1175px 332px #fff, 1050px 728px #fff, 1127px 677px #fff, 1560px 420px #fff,
    716px 87px #fff, 1253px 1193px #fff, 413px 1682px #fff, 1919px 1539px #fff,
    848px 1424px #fff, 1510px 143px #fff;
}

#stars2 {
  width: 2px;
  height: 2px;
  background: transparent;
  box-shadow: 237px 237px #fff, 32px 581px #fff, 88px 322px #fff,
    107px 1350px #fff, 1883px 1078px #fff, 569px 1354px #fff, 1468px 1039px #fff,
    87px 723px #fff, 896px 1634px #fff, 1629px 1565px #fff, 1467px 965px #fff,
    277px 1230px #fff, 900px 1711px #fff, 344px 166px #fff, 268px 1154px #fff,
    538px 1615px #fff, 1617px 1732px #fff, 500px 102px #fff, 954px 1570px #fff,
    545px 645px #fff, 256px 1241px #fff, 1289px 715px #fff, 760px 1514px #fff,
    547px 307px #fff, 1400px 1556px #fff, 1263px 1209px #fff, 614px 1026px #fff,
    608px 1700px #fff, 1929px 1722px #fff, 1168px 1046px #fff, 1972px 418px #fff,
    830px 1943px #fff, 1329px 913px #fff, 1076px 1032px #fff, 1728px 1720px #fff,
    306px 804px #fff, 319px 1848px #fff, 703px 1902px #fff, 1918px 241px #fff,
    1371px 1827px #fff, 1576px 1367px #fff, 1070px 1762px #fff, 1044px 2px #fff,
    595px 1202px #fff, 993px 435px #fff, 643px 914px #fff, 628px 1185px #fff,
    1717px 82px #fff, 1352px 1289px #fff, 1734px 115px #fff, 1456px 960px #fff,
    1689px 917px #fff, 1376px 1371px #fff, 479px 1738px #fff, 471px 1521px #fff,
    169px 117px #fff, 906px 1601px #fff, 1646px 1699px #fff, 1462px 1848px #fff,
    187px 190px #fff, 310px 1189px #fff, 1473px 298px #fff, 1607px 795px #fff,
    1392px 382px #fff, 1798px 1024px #fff, 609px 1921px #fff, 505px 280px #fff,
    1212px 1442px #fff, 887px 904px #fff, 1298px 1887px #fff, 1757px 1135px #fff,
    89px 1094px #fff, 1636px 285px #fff, 189px 1673px #fff, 1386px 1646px #fff,
    15px 1086px #fff, 303px 1098px #fff, 1413px 470px #fff, 1209px 1375px #fff,
    1378px 1199px #fff, 288px 629px #fff, 1692px 1167px #fff, 1018px 149px #fff,
    1888px 394px #fff, 1155px 1562px #fff, 1748px 1887px #fff, 434px 972px #fff,
    990px 1386px #fff, 1169px 420px #fff, 1658px 779px #fff, 1859px 657px #fff,
    217px 220px #fff, 1466px 127px #fff, 636px 777px #fff, 89px 1502px #fff,
    891px 426px #fff, 129px 91px #fff, 1971px 1862px #fff, 541px 1835px #fff,
    552px 1923px #fff, 1774px 1323px #fff, 544px 1310px #fff, 353px 1782px #fff,
    990px 196px #fff, 37px 1018px #fff, 419px 1330px #fff, 1559px 1185px #fff,
    592px 942px #fff, 1188px 1836px #fff, 1171px 1166px #fff, 648px 1934px #fff,
    461px 1518px #fff, 1495px 1015px #fff, 1839px 733px #fff, 248px 852px #fff,
    1445px 1507px #fff, 953px 604px #fff, 1572px 1760px #fff, 1364px 1854px #fff,
    1105px 302px #fff, 1638px 990px #fff, 1462px 618px #fff, 1906px 1144px #fff,
    1819px 550px #fff, 418px 911px #fff, 1171px 1987px #fff, 1670px 1958px #fff,
    1353px 905px #fff, 1543px 1249px #fff, 923px 340px #fff, 1355px 472px #fff,
    779px 856px #fff, 923px 431px #fff, 743px 301px #fff, 1510px 934px #fff,
    452px 1236px #fff, 389px 1873px #fff, 153px 325px #fff, 843px 345px #fff,
    1427px 205px #fff, 1489px 837px #fff, 84px 90px #fff, 1090px 720px #fff,
    1965px 877px #fff, 1867px 666px #fff, 394px 581px #fff, 1680px 951px #fff,
    1731px 209px #fff, 1979px 179px #fff, 1183px 1287px #fff, 1674px 1630px #fff,
    1514px 5px #fff, 1848px 11px #fff, 109px 1674px #fff, 166px 1849px #fff,
    324px 1982px #fff, 1395px 587px #fff, 682px 238px #fff, 1180px 642px #fff,
    267px 295px #fff, 1745px 637px #fff, 1930px 1192px #fff, 1447px 531px #fff,
    507px 871px #fff, 627px 1755px #fff, 183px 651px #fff, 1777px 1482px #fff,
    1272px 1393px #fff, 1372px 1683px #fff, 1794px 921px #fff, 451px 1034px #fff,
    695px 57px #fff, 1305px 1783px #fff, 145px 861px #fff, 1967px 295px #fff,
    87px 1663px #fff, 1450px 295px #fff, 595px 1942px #fff, 1769px 1877px #fff,
    1417px 1428px #fff, 1276px 621px #fff, 1689px 364px #fff, 408px 290px #fff,
    374px 182px #fff, 119px 1552px #fff, 18px 1782px #fff, 1413px 300px #fff,
    1705px 1500px #fff, 798px 1133px #fff, 1112px 774px #fff, 1267px 125px #fff,
    1860px 519px #fff, 1017px 1196px #fff, 1162px 1194px #fff,
    1998px 1000px #fff, 1011px 1517px #fff, 145px 1010px #fff, 1963px 158px #fff,
    1188px 1826px #fff, 442px 261px #fff;
  animation: animStar 100s linear infinite;
}
#stars2:after {
  content: " ";
  position: absolute;
  top: 2000px;
  width: 2px;
  height: 2px;
  background: transparent;
  box-shadow: 237px 237px #fff, 32px 581px #fff, 88px 322px #fff,
    107px 1350px #fff, 1883px 1078px #fff, 569px 1354px #fff, 1468px 1039px #fff,
    87px 723px #fff, 896px 1634px #fff, 1629px 1565px #fff, 1467px 965px #fff,
    277px 1230px #fff, 900px 1711px #fff, 344px 166px #fff, 268px 1154px #fff,
    538px 1615px #fff, 1617px 1732px #fff, 500px 102px #fff, 954px 1570px #fff,
    545px 645px #fff, 256px 1241px #fff, 1289px 715px #fff, 760px 1514px #fff,
    547px 307px #fff, 1400px 1556px #fff, 1263px 1209px #fff, 614px 1026px #fff,
    608px 1700px #fff, 1929px 1722px #fff, 1168px 1046px #fff, 1972px 418px #fff,
    830px 1943px #fff, 1329px 913px #fff, 1076px 1032px #fff, 1728px 1720px #fff,
    306px 804px #fff, 319px 1848px #fff, 703px 1902px #fff, 1918px 241px #fff,
    1371px 1827px #fff, 1576px 1367px #fff, 1070px 1762px #fff, 1044px 2px #fff,
    595px 1202px #fff, 993px 435px #fff, 643px 914px #fff, 628px 1185px #fff,
    1717px 82px #fff, 1352px 1289px #fff, 1734px 115px #fff, 1456px 960px #fff,
    1689px 917px #fff, 1376px 1371px #fff, 479px 1738px #fff, 471px 1521px #fff,
    169px 117px #fff, 906px 1601px #fff, 1646px 1699px #fff, 1462px 1848px #fff,
    187px 190px #fff, 310px 1189px #fff, 1473px 298px #fff, 1607px 795px #fff,
    1392px 382px #fff, 1798px 1024px #fff, 609px 1921px #fff, 505px 280px #fff,
    1212px 1442px #fff, 887px 904px #fff, 1298px 1887px #fff, 1757px 1135px #fff,
    89px 1094px #fff, 1636px 285px #fff, 189px 1673px #fff, 1386px 1646px #fff,
    15px 1086px #fff, 303px 1098px #fff, 1413px 470px #fff, 1209px 1375px #fff,
    1378px 1199px #fff, 288px 629px #fff, 1692px 1167px #fff, 1018px 149px #fff,
    1888px 394px #fff, 1155px 1562px #fff, 1748px 1887px #fff, 434px 972px #fff,
    990px 1386px #fff, 1169px 420px #fff, 1658px 779px #fff, 1859px 657px #fff,
    217px 220px #fff, 1466px 127px #fff, 636px 777px #fff, 89px 1502px #fff,
    891px 426px #fff, 129px 91px #fff, 1971px 1862px #fff, 541px 1835px #fff,
    552px 1923px #fff, 1774px 1323px #fff, 544px 1310px #fff, 353px 1782px #fff,
    990px 196px #fff, 37px 1018px #fff, 419px 1330px #fff, 1559px 1185px #fff,
    592px 942px #fff, 1188px 1836px #fff, 1171px 1166px #fff, 648px 1934px #fff,
    461px 1518px #fff, 1495px 1015px #fff, 1839px 733px #fff, 248px 852px #fff,
    1445px 1507px #fff, 953px 604px #fff, 1572px 1760px #fff, 1364px 1854px #fff,
    1105px 302px #fff, 1638px 990px #fff, 1462px 618px #fff, 1906px 1144px #fff,
    1819px 550px #fff, 418px 911px #fff, 1171px 1987px #fff, 1670px 1958px #fff,
    1353px 905px #fff, 1543px 1249px #fff, 923px 340px #fff, 1355px 472px #fff,
    779px 856px #fff, 923px 431px #fff, 743px 301px #fff, 1510px 934px #fff,
    452px 1236px #fff, 389px 1873px #fff, 153px 325px #fff, 843px 345px #fff,
    1427px 205px #fff, 1489px 837px #fff, 84px 90px #fff, 1090px 720px #fff,
    1965px 877px #fff, 1867px 666px #fff, 394px 581px #fff, 1680px 951px #fff,
    1731px 209px #fff, 1979px 179px #fff, 1183px 1287px #fff, 1674px 1630px #fff,
    1514px 5px #fff, 1848px 11px #fff, 109px 1674px #fff, 166px 1849px #fff,
    324px 1982px #fff, 1395px 587px #fff, 682px 238px #fff, 1180px 642px #fff,
    267px 295px #fff, 1745px 637px #fff, 1930px 1192px #fff, 1447px 531px #fff,
    507px 871px #fff, 627px 1755px #fff, 183px 651px #fff, 1777px 1482px #fff,
    1272px 1393px #fff, 1372px 1683px #fff, 1794px 921px #fff, 451px 1034px #fff,
    695px 57px #fff, 1305px 1783px #fff, 145px 861px #fff, 1967px 295px #fff,
    87px 1663px #fff, 1450px 295px #fff, 595px 1942px #fff, 1769px 1877px #fff,
    1417px 1428px #fff, 1276px 621px #fff, 1689px 364px #fff, 408px 290px #fff,
    374px 182px #fff, 119px 1552px #fff, 18px 1782px #fff, 1413px 300px #fff,
    1705px 1500px #fff, 798px 1133px #fff, 1112px 774px #fff, 1267px 125px #fff,
    1860px 519px #fff, 1017px 1196px #fff, 1162px 1194px #fff,
    1998px 1000px #fff, 1011px 1517px #fff, 145px 1010px #fff, 1963px 158px #fff,
    1188px 1826px #fff, 442px 261px #fff;
}

#stars3 {
  width: 3px;
  height: 3px;
  background: transparent;
  box-shadow: 1566px 1002px #fff, 1757px 309px #fff, 658px 366px #fff,
    1831px 1900px #fff, 335px 624px #fff, 305px 22px #fff, 1846px 879px #fff,
    777px 1670px #fff, 985px 1491px #fff, 15px 1768px #fff, 909px 1592px #fff,
    961px 61px #fff, 585px 1671px #fff, 562px 1328px #fff, 850px 856px #fff,
    1470px 1609px #fff, 1304px 1323px #fff, 1614px 1320px #fff, 539px 721px #fff,
    14px 1732px #fff, 778px 1381px #fff, 1059px 1029px #fff, 163px 64px #fff,
    941px 1514px #fff, 1734px 1257px #fff, 538px 1313px #fff, 1254px 214px #fff,
    1823px 1073px #fff, 1313px 342px #fff, 1472px 3px #fff, 643px 706px #fff,
    321px 883px #fff, 1121px 1014px #fff, 1134px 776px #fff, 624px 462px #fff,
    1675px 1411px #fff, 1334px 201px #fff, 705px 831px #fff, 1765px 1300px #fff,
    1810px 1785px #fff, 746px 736px #fff, 1118px 455px #fff, 1584px 1329px #fff,
    1179px 1078px #fff, 1924px 1912px #fff, 664px 1676px #fff, 580px 954px #fff,
    502px 1584px #fff, 181px 1803px #fff, 1348px 1327px #fff, 1051px 526px #fff,
    235px 765px #fff, 861px 1208px #fff, 953px 785px #fff, 26px 1271px #fff,
    1471px 770px #fff, 1185px 1444px #fff, 1839px 1298px #fff, 1124px 679px #fff,
    740px 1285px #fff, 1995px 17px #fff, 1509px 1004px #fff, 1415px 1318px #fff,
    871px 111px #fff, 1515px 385px #fff, 1903px 841px #fff, 753px 1538px #fff,
    119px 108px #fff, 1713px 924px #fff, 1153px 256px #fff, 75px 501px #fff,
    4px 1964px #fff, 477px 246px #fff, 968px 883px #fff, 1488px 422px #fff,
    673px 400px #fff, 1746px 1430px #fff, 1935px 553px #fff, 1257px 1212px #fff,
    1937px 1278px #fff, 870px 171px #fff, 379px 541px #fff, 759px 1937px #fff,
    1762px 1612px #fff, 1624px 47px #fff, 322px 306px #fff, 1495px 430px #fff,
    931px 1784px #fff, 1296px 413px #fff, 160px 1703px #fff, 376px 717px #fff,
    1444px 1308px #fff, 1456px 1680px #fff, 1769px 1399px #fff,
    1690px 638px #fff, 870px 1673px #fff, 1089px 232px #fff, 848px 1462px #fff,
    1627px 1559px #fff, 430px 632px #fff;
  animation: animStar 150s linear infinite;
}
#stars3:after {
  content: " ";
  position: absolute;
  top: 2000px;
  width: 3px;
  height: 3px;
  background: transparent;
  box-shadow: 1566px 1002px #fff, 1757px 309px #fff, 658px 366px #fff,
    1831px 1900px #fff, 335px 624px #fff, 305px 22px #fff, 1846px 879px #fff,
    777px 1670px #fff, 985px 1491px #fff, 15px 1768px #fff, 909px 1592px #fff,
    961px 61px #fff, 585px 1671px #fff, 562px 1328px #fff, 850px 856px #fff,
    1470px 1609px #fff, 1304px 1323px #fff, 1614px 1320px #fff, 539px 721px #fff,
    14px 1732px #fff, 778px 1381px #fff, 1059px 1029px #fff, 163px 64px #fff,
    941px 1514px #fff, 1734px 1257px #fff, 538px 1313px #fff, 1254px 214px #fff,
    1823px 1073px #fff, 1313px 342px #fff, 1472px 3px #fff, 643px 706px #fff,
    321px 883px #fff, 1121px 1014px #fff, 1134px 776px #fff, 624px 462px #fff,
    1675px 1411px #fff, 1334px 201px #fff, 705px 831px #fff, 1765px 1300px #fff,
    1810px 1785px #fff, 746px 736px #fff, 1118px 455px #fff, 1584px 1329px #fff,
    1179px 1078px #fff, 1924px 1912px #fff, 664px 1676px #fff, 580px 954px #fff,
    502px 1584px #fff, 181px 1803px #fff, 1348px 1327px #fff, 1051px 526px #fff,
    235px 765px #fff, 861px 1208px #fff, 953px 785px #fff, 26px 1271px #fff,
    1471px 770px #fff, 1185px 1444px #fff, 1839px 1298px #fff, 1124px 679px #fff,
    740px 1285px #fff, 1995px 17px #fff, 1509px 1004px #fff, 1415px 1318px #fff,
    871px 111px #fff, 1515px 385px #fff, 1903px 841px #fff, 753px 1538px #fff,
    119px 108px #fff, 1713px 924px #fff, 1153px 256px #fff, 75px 501px #fff,
    4px 1964px #fff, 477px 246px #fff, 968px 883px #fff, 1488px 422px #fff,
    673px 400px #fff, 1746px 1430px #fff, 1935px 553px #fff, 1257px 1212px #fff,
    1937px 1278px #fff, 870px 171px #fff, 379px 541px #fff, 759px 1937px #fff,
    1762px 1612px #fff, 1624px 47px #fff, 322px 306px #fff, 1495px 430px #fff,
    931px 1784px #fff, 1296px 413px #fff, 160px 1703px #fff, 376px 717px #fff,
    1444px 1308px #fff, 1456px 1680px #fff, 1769px 1399px #fff,
    1690px 638px #fff, 870px 1673px #fff, 1089px 232px #fff, 848px 1462px #fff,
    1627px 1559px #fff, 430px 632px #fff;
}

#title {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  color: #fff;
  text-align: center;
  font-family: "lato", sans-serif;
  font-weight: 300;
  font-size: 50px;
  letter-spacing: 10px;
  margin-top: -60px;
  padding-left: 10px;
}
#title span {
  background: -webkit-linear-gradient(white, #38495a);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

@keyframes animStar {
  from {
    transform: translateY(0px);
  }
  to {
    transform: translateY(-2000px);
  }
}

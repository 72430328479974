.App {
  --primary: #0d47a1;
  --primary-light: #5472d3;
  --primary-dark: #002171;
  --primary-text: white;
  --secondary: #2962ff;
  --secondary-light: #768fff;
  --secondary-dark: #0039cb;
  --bkg-color: #0d47a1;
  --secondary-text: white;
  --surface: rgb(240, 240, 240);
  --correct: #00ff00;
  --wrong: #ffa500;
  --missing: #ff0000;
}

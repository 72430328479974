.cv__header {
  font-weight: bold;
  margin-top: 15%;
}

img {
  width: 100%;
}

.cv__text {
  vertical-align: center;
}

.row {
  display: flex;
}

.col {
  margin-left: 0;
}

.container {
  padding: 0;
}

.cv__item {
  font-size: 20px;
  transition: all 0.3s ease-in-out;
}

.cv__item:hover {
  color: var(--primary);
  transition: all 0.3s ease-in-out;
}

.cv__desc {
  font-style: italic;
}

.cv__date {
  font-size: 15px;
  font-weight: lighter;
  color: var(--primary-light);
}

.nav-link {
  color: var(--primary-light) !important;
  font-weight: bold !important;
  font-size: 20px !important;
}

.nav-link:hover {
  color: var(--primary) !important;
}

.navbar-brand {
  font-size: 28px !important;
}

.home__row {
  margin: 10% !important;
}

.home__name {
  font-weight: 100;
  font-weight: bold;
}

.home__position {
  margin-top: 5%;
}

.home__info {
  font-size: larger;
  color: var(--primary-light);
}

.home__linkedin {
  transform: scale(2);
  margin-top: 5%;
  color: var(--primary);
}

.home__section {
  font-weight: bold;
  margin-top: 10%;
}

.home__subsection {
  font-weight: bold;
}

.home__icons {
  transform: scale(2);
}

.home__photo {
  border-radius: 50% !important;
  width: 288px !important;
  align-items: center !important;
}

.home__row__photo {
  justify-content: center;
}

.home__row__logos {
  max-width: 200px;
  margin: 30px auto auto auto !important;
}

.home__resume {
  justify-content: center !important;
}

.publication__card__title {
  font-weight: bold;
  font-size: larger;
  margin-bottom: 2%;
  color: var(--primary-light);
}

.publication__card__authors {
  font-size: medium;
  font-weight: bold;
  text-align: center;
  color: var(--primary-text);
  margin: 5%;
}

.publication__card__journal {
  font-size: medium;
  font-style: italic;
  text-align: center;
  color: var(--primary-text);
}

.publication__card {
  max-width: 700px;
  min-width: 300px;
  margin: 10px auto 10px auto;
}

.publication__image__conference {
  max-width: 300px;
}

.publication__image__grid {
}

.publications__card__actions {
  justify-content: flex-end !important;
}

.teaching__card__title {
  font-weight: bold;
  font-size: larger;
  margin-bottom: 2%;
  color: var(--primary-text);
}

.teaching__card__desc {
  font-size: medium;
  font-style: italic;
  text-align: left;
  color: var(--primary-text);
}

.MuiCard-root {
  opacity: 0.8 !important;
  background-color: black !important;
}

.teaching__card {
  max-width: 400px;
  min-width: 300px;
  margin: 0 auto 0 auto;
}

.card-disabled {
  filter: blur(10px);
}

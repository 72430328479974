.teaching__header {
  font-weight: bold;
  margin-top: 5%;
  margin-bottom: 5%;
}

.teaching {
  align-items: center;
}

.MuiGrid-root {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.MuiGrid-root::-webkit-scrollbar {
  display: none;
}
